.button {
  text-decoration: none;
  border: 1px solid var(--color-txt-go-to-cpe);
  width: 250px;
  display: inline-block;
  font-size: var(--text-size);
  font-family: var(--text-family);
  font-weight: var(--text-weight-go-to-cpe);
  color: var(--color-txt-go-to-cpe);
  text-align: center;
  padding: 14px 0 15px;
}
