.root {
  background-color: var(--color-bg-go-to-cpe);
  padding: 11px 0 15px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.text {
  font-size: var(--text-size);
  font-family: var(--text-family);
  font-weight: var(--text-weight-go-to-cpe);
  color: var(--color-txt-go-to-cpe);
  text-align: center;
  line-height: var(--text-line-height-go-to-cpe);
  margin: 18px auto;
}

.bloc1 {
  max-width: 416px;
}

.bloc2 {
  max-width: 566px;
}

.buttonWrapper {
  margin: 45px;
}

.buttonSpacer {
  display: inline-block;
  width: 83px;
}

@media screen and (max-width: 700px) {
  .buttonWrapper {
    margin-bottom: 35px;
  }

  .buttonSpacer {
    display: block;
    height: 25px;
  }

  .text {
    padding: 0 15px;
  }
}
