.root {
  background: var(--color-gradient-start);
  background: linear-gradient(var(--gradient-angle), var(--color-gradient-start) 0%, var(--color-gradient-end) 100%);
  overflow: hidden;
}

.wrapper {
  height: 620px;
  width: var(--wrapper-size);
  margin: auto;
  position: relative;
}

.tagLine {
  text-transform: uppercase;
  font-size: var(--text-size-tag-line);
  font-family: var(--text-family);
  font-weight: var(--text-weight-tag-line);
  color: var(--color-txt-tag-line);
  line-height: 1.25;
  margin: 0;
  text-align: right;
  letter-spacing: 1.11px;
  padding-right: 24px;
  position: relative;
  padding-top: 21px;
}

.relativeTagLine {
  display: inline-block;
}

.mainTagLine {
  display: inline-block;
  transform: rotate(90deg) translate(210px, -90px);
  position: absolute;
  bottom: 0;
  right: 0;
}

.educatorImg {
  position: absolute;
}

.welcomingEducatorImg {
  top: 85px;
  left: 85px;
  width: auto;
  height: 800px;
}

.partnerEducatorImg {
  bottom: -100px;
  left: -175px;
  width: 800px;
  height: auto;
}

.creativeEducatorImg {
  top: 75px;
  left: -15px;
  width: auto;
  height: 800px;
}

.bloomingEducatorImg {
  top: 0;
  left: 0;
  width: auto;
  height: 800px;
}

.involvedEducatorImg {
  top: 50px;
  left: -75px;
  width: auto;
  height: 800px;
}

.imageBlock {
  position: relative;
  height: 100%;
}

.concilivi {
  position: absolute;
  right: 24px;
  bottom: 21px;
  width: 116px;
  height: 116px;
}

@media screen and (max-width: 768px) {
  .wrapper {
    width: 100%;
  }

  .partnerEducatorImg {
    width: 765px;
  }
}

@media screen and (max-width: 630px) {
  .tagLine {
    font-size: 70px;
  }

  .wrapper {
    height: 600px;
  }

  .mainTagLine {
    transform: rotate(90deg) translate(190px, -76px);
  }

  .concilivi {
    width: 100px;
    height: 100px;
  }

  .welcomingEducatorImg {
    top: 85px;
    height: 780px;
  }

  .partnerEducatorImg {
    width: 730px;
  }

  .creativeEducatorImg {
    height: 780px;
  }

  .bloomingEducatorImg {
    height: 780px;
  }

  .involvedEducatorImg {
    top: 70px;
    height: 750px;
  }
}

@media screen and (max-width: 565px) {
  .tagLine {
    font-size: 60px;
  }

  .wrapper {
    height: 560px;
  }

  .mainTagLine {
    transform: rotate(90deg) translate(160px, -62px);
  }

  .concilivi {
    width: 90px;
    height: 90px;
  }

  .welcomingEducatorImg {
    top: 77px;
    left: 40px;
    height: 770px;
  }

  .partnerEducatorImg {
    width: 690px;
  }

  .creativeEducatorImg {
    left: -50px;
    height: 720px;
  }

  .bloomingEducatorImg {
    left: -15px;
    height: 720px;
  }

  .involvedEducatorImg {
    left: -110px;
    height: 710px;
  }
}

@media screen and (max-width: 510px) {
  .partnerEducatorImg {
    width: 650px;
  }
}

@media screen and (max-width: 490px) {
  .tagLine {
    font-size: 50px;
  }

  .wrapper {
    height: 500px;
  }

  .mainTagLine {
    transform: rotate(90deg) translate(140px, -48px);
  }

  .concilivi {
    width: 80px;
    height: 80px;
  }

  .welcomingEducatorImg {
    top: 67px;
    left: 20px;
    height: 680px;
  }

  .partnerEducatorImg {
    width: 615px;
    left: -160px;
  }

  .creativeEducatorImg {
    left: -75px;
    height: 640px;
  }

  .bloomingEducatorImg {
    left: -30px;
    height: 640px;
  }

  .involvedEducatorImg {
    left: -135px;
    height: 640px;
  }
}

@media screen and (max-width: 450px) {
  .partnerEducatorImg {
    width: 580px;
    left: -150px;
  }
}

@media screen and (max-width: 410px) {
  .tagLine {
    font-size: 40px;
  }

  .wrapper {
    height: 450px;
  }

  .mainTagLine {
    transform: rotate(90deg) translate(108px, -34px);
  }

  .concilivi {
    width: 80px;
    height: 80px;
  }

  .welcomingEducatorImg {
    top: 58px;
    left: 10px;
    height: 580px;
  }

  .partnerEducatorImg {
    width: 520px;
    left: -130px;
  }

  .creativeEducatorImg {
    left: -75px;
    height: 550px;
  }

  .bloomingEducatorImg {
    height: 560px;
  }

  .involvedEducatorImg {
    left: -120px;
    height: 570px;
  }
}
