.root {
  background-color: var(--color-top-banner);
  height: 110px;
}

.wrapper {
  width: var(--wrapper-size);
  margin: auto;
}

.logo {
  margin: 12px 0 17px 50px;
}

@media screen and (max-width: 768px) {
  .wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .wrapper {
    text-align: center;
  }

  .logo {
    margin-left: auto;
    margin-right: auto;
  }
}
