/* Added from PostCSS Normalize */
@import-sanitize;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --color-red: #c0272d;
  --color-white: #ffffff;
  --color-blue: #305a92;
  --color-yellow: #e6b60b;
  --color-orange: #d66000;

  --color-top-banner: var(--color-white);
  --color-txt-tag-line: var(--color-red);
  --color-gradient-start: var(--color-orange);
  --color-gradient-end: var(--color-yellow);
  --color-bg-go-to-cpe: var(--color-red);
  --color-txt-go-to-cpe: var(--color-white);
  --color-bottom-banner: var(--color-blue);

  --wrapper-size: 768px;

  --gradient-angle: 35deg;

  --text-size: 18px;
  --text-size-tag-line: 80px;
  --text-family: Poppins;
  --text-weight-go-to-cpe: 400;
  --text-weight-tag-line: 700;
  --text-line-height-go-to-cpe: 25px;
}
